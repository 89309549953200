import { Layout } from '@/components/Layout/Layout';
import ClientProductsCategorySlugPage from 'components/PageLevelComponents/ClientProductsCategorySlugPage';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { ANONYMOUS_TOKEN_NAME, LOCALE_DEFAULT } from 'config/config';
import { GetServerSideProps } from 'next';
import { ProductsCategoryPageController } from 'server/controller/page/products/categoryPageController';
import {
  DictionaryItem,
  ProductCategoryPage,
} from 'server/repository/hygraph/generated/graphqlTypes';
import { IProductsCategoryPageResource } from 'server/resource/page/productsCategoryPageResource';

const RootPage = (props: IProductsCategoryPageResource) => {
  const {
    metaData,
    searchItemLabels,
    recipeCopy,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    learnMoreText,
    dynamicPageComponents,
    productsText,
    followUsData,
    categoryProducts,
    productCategoryPage,
    globalLabels,
    hideSubCategories,
    pathTranslations,
    userToken,
  } = props;

  return (
    <Layout
      themeName={undefined}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      userToken={userToken}
    >
      <ClientProductsCategorySlugPage
        key={productCategoryPage?.id}
        learnMoreText={learnMoreText as DictionaryItem}
        productsText={productsText as DictionaryItem}
        followUsData={followUsData as unknown as ISocialMediaComponent}
        categoryProducts={categoryProducts}
        productCategoryPage={productCategoryPage as ProductCategoryPage}
        breadcrumbsCMSData={breadcrumbsCMSData}
        recipeCopy={recipeCopy}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData}
        difficultyCopy={difficultyCopy as Record<string, string>}
        dynamicPageComponents={dynamicPageComponents}
        globalLabels={globalLabels}
        hideSubCategories={hideSubCategories}
      />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
  req,
}) => {
  const anonymousToken = req.cookies[ANONYMOUS_TOKEN_NAME];
  return ProductsCategoryPageController.getCachedCategoryPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: Array.isArray(params?.slug) ? params.slug.join('/') : params?.slug ?? '',
    userToken: anonymousToken ?? '',
  });
};

export default RootPage;
